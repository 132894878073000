import servicesData from '../data/services.json'

export function Services (props)
{
    return( 
        <section id = "services" className = "services section-bg">
            <div className = "container">
                <div className = "section-title"> <h2> תחומי טיפול </h2> </div>
                <div className = "row">
                    {servicesData.servicesData.map((service,index) => 
                        <div className = "col-lg-4 col-md-6 icon-box" data-aos = "fade-up" key = {index}>
                        <div className = "icon"> <i className = {service.icon}> </i></div>
                        <h4 className = "title">{service.title}</h4>
                        <p className = "description">{service.description} </p> 
                        </div> 
                    )}
                </div>
            </div> 
      </section>
    )
}

export default Services
