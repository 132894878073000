import {useState,useEffect} from 'react';
import recipes from '../data/recipes.json'
import RecipesDialog from './RecipesDialog'

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';


export function Recipes(props){

  const [recipesDialogOpen, setRecipesDialogOpen] = useState(false);
  const [recipeIndex, setRecipeIndex] = useState(false);

  const breakpoints = {xs: 0,sm: 600,md: 960,lg: 1280,xl: 1920}

  const getColumns = (width) => {
      if (width < breakpoints.sm) {
          return 1
      } else if (width < breakpoints.md) {
          return 2
      } else {
          return 3
      }
  }

  const [columns, setColumns] = useState(getColumns(window.innerWidth))
  const updateDimensions = () => {
      setColumns(getColumns(window.innerWidth))
  }

  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openRecipesDialog = (e,index) => {
    e.stopPropagation()
    e.preventDefault()
    setRecipeIndex(index)
    setRecipesDialogOpen(true);
  };

  const closeRecipesDialog = () => {
    setRecipesDialogOpen(false);
  };


  return (
    <>
      <RecipesDialog 
        recipesDialogOpen = {recipesDialogOpen} 
        closeRecipesDialog = {closeRecipesDialog} 
        recipeIndex  = {recipeIndex}
      />

      <section id = "recipes" className = "recipes section-bg">
        <div className = "container" data-aos = "fade-up">
          <div className = "section-title">
            <h2>מתכונים בריאים וטעימים וללא גלוטן</h2>
            <p>במטבח שלי אני מקפידה להשתמש במצרכים טבעיים, בריאים, ואיכותיים. משתפת אותכם במתכונים שלי שהם ברוח התזונה הים תיכונית.</p>
          </div>
            <ImageList cols = {columns} gap={8} >
              {recipes.recipesData.map((item,index) => (
                <ImageListItem key={index} onClick={(e) => {openRecipesDialog(e,index)}} style={{cursor:'pointer'}}>
                  <img
                    src={`${"assets/img/recipes/" + item.MenuImageFile}?w=248&fit=crop&auto=format`}
                    srcSet={`${"assets/img/recipes/" + item.MenuImageFile}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.title}
                    actionIcon={
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                      >
                      <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>

          {/* <div className = "row recipes-container" data-aos = "fade-up" data-aos-delay = "200">
              {recipes.recipesData.map((recipe,index) => 

                <div className = "col-lg-4 col-md-6 recipes-item filter-app" key={index}>
                  <div className = "recipes-wrap" onClick={(e) => {openRecipesDialog(e,index)}}>
                    <img src = {"assets/img/recipes/" + recipe.MenuImageFile} className = "img-fluid" alt = "recipe" />
                      <div className = "recipes-info">
                        <h4 style={{ fontSize: '30px' , color:'#4066d4'}}> {recipe.title} </h4> 
                    </div> 
                  </div> 
                </div>
              )}
          </div> */}

        </div> 
      </section>
    </>
  );
};

export default Recipes