import {useState} from 'react';
import { send } from 'emailjs-com';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export function Contact (props)
{

    const [whatsAppText, setWhatsAppText] = useState("");
    const [toSend, setToSend] = useState({
      from_name: '',
      from_email: '',
      from_phone: '',
      message: '',
    });

    const [alertMessage,       setAlertMessage]        = useState("");
    const [alertOpen,          setAlertOpen]           = useState(false);
    const [severity,           setSeverity]           = useState("success");

    const  sendWhatsapp = () => {
        var url = "https://wa.me/972522541131?text=" + whatsAppText;
        window.open(url, '_blank');
    }

    const handleAlertClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertOpen(false);
    };
  
    const  sendEmail = (e) => {
      e.preventDefault();

      send(
        process.env.REACT_APP_SERVICE,
        process.env.REACT_APP_TEMPLATE,
        toSend,
        process.env.REACT_APP_MAFTEACH
      )
        .then((response) => {
            console.log('Message SUCCESS!', response.status, response.text);
            setAlertMessage(" תודה על הפניה, אחזור אליך בהקדם")
            setSeverity("success")
            setAlertOpen(true)
            setToSend({from_name: '', from_email: '', from_phone: '', message: '',}) 
        })

        .catch((err) => {
            setAlertMessage("בעיה בשליחת ההודעה: " + err.text)
            setSeverity("error")
            setAlertOpen(true)
            console.log('Message FAILED...', err);
        });
    }

    const  messageChange = (e) => {
      setToSend({ ...toSend, [e.target.name]: e.target.value });
    }


    return( 

      <>
        <Snackbar 
          open={alertOpen} 
          autoHideDuration={6000} 
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <Alert 
              dir = "rtl" 
              sx={{textAlign: "right", width: '100%'}} 
              severity= {severity} >
                  {alertMessage}
            </Alert>
        </Snackbar>


        <section id = "contact" className = "contact"> 
        <div className = "container">
          <div className = "section-title">
            <h2> צור קשר </h2> 
            <p> אשמח לפגוש אתכם בקליניקה הפרטית שלי בתל מונד או במרפאת מכבי שירותי בריאות בסניף חדרה או כפר סבא הירוקה.אנא מלאו את הטופס ואחזור אליכם בהקדם </p> 
          </div>

          <div className = "row" data-aos = "fade-in">
            <div className = "col-lg-5 d-flex align-items-stretch">
              <div className = "info">
                
              <div className="address">
                <a href="https://ul.waze.com/ul?place=ChIJcVpk86E_HRURz8aYsnDCTXA&ll=32.25318360%2C34.92231170&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
                        target="_blank" rel="noreferrer"><i className="bi bi-geo-alt"></i></a>
                <h4>כתובת:</h4>
                <p>האהבה 7, תל מונד</p>
              </div>

                <div className = "email">
                  <a href = "mailto:roni@hollander.fm" target = "_blank" rel="noreferrer"> <i className = "bi bi-envelope"> </i></a>
                  <h4> אימייל: </h4> 
                  <p> roni @hollander.fm </p> 
                </div> 

                <div className = "phone">
                  <a href = "tel:+972522541131" target = "_blank" rel="noreferrer"> <i className = "bi bi-phone"> </i></a>
                  <h4> טלפון: </h4> 
                  <p> 052-2541131 </p> 
                </div> 

                <div>
                  <iframe 
                    width = "100%" 
                    height = "290" 
                    id = "gmap_canvas" 
                    src = "https://maps.google.com/maps?q=Roni%20Hollander&t=&z=14&ie=UTF8&iwloc=&output=embed"
                    title = "Map"
                    frameBorder = "0"
                    scrolling = "no"
                    marginHeight = "0"
                    marginWidth = "0"> 
                  </iframe> 
                </div> 
              </div> 
            </div>

            <div className = "send-message col-lg-7 mt-5 mt-lg-0  align-items-stretch">

              <form className = "php-email-form" onSubmit={sendEmail} >
                <div className = "row">
                 
                  <div className = "form-group col-md-6">
                    <label htmlFor = "name"> שם </label> 
                    <input 
                        type = "text" 
                        name = "from_name" 
                        className = "form-control" 
                        id = "from_name" 
                        value={toSend.from_name}
                        onChange={messageChange}
                        required/>
                  </div> 
                
                  <div className = "form-group col-md-6">
                    <label htmlFor = "email"> אימייל </label> 
                    <input 
                        type = "email" 
                        className = "form-control" 
                        name = "from_email" 
                        id = "from_email" 
                        value={toSend.from_email}
                        onChange={messageChange}                        
                        required/>
                  </div> 
                </div>

                <div className = "form-group">
                    <label htmlFor = "phone"> טלפון </label> 
                    <input 
                        type = "text" 
                        className = "form-control" 
                        name = "from_phone" 
                        id = "from_phone" 
                        value={toSend.from_phone}
                        onChange={messageChange}                        
                        required/>
                </div> 
                
                <div className = "form-group">
                  <label htmlFor = "name"> הודעה </label> 
                    <textarea 
                        className = "form-control" 
                        name = "message" 
                        rows = "5" 
                        value={toSend.message}
                        onChange={messageChange}                        
                        required> 
                    </textarea> 
                </div>

                <div className = "text-center">
                  <button type = "submit"> <i className = "bi bi-send"> </i>&nbsp;&nbsp;שלח</button>
                </div>
              </form>


              {/* <!--whatsapp message--> */}
              <form className = "whatsapp-form"  onSubmit = {sendWhatsapp}>
                <div className = "form-group ">
                  <button type = "submit" className = "button"> 
                    <img src = "./assets/img/whatsapp.png" height = "40px" width = "40px" alt = "Whatsapp"/> 
                  </button> 
                </div> 

                <div className = "form-group whatsapp-form-group">
                  <input type = "text" 
                      className = "form-control" 
                      name = "whatsapp" 
                      id = "whatsapp-text"
                      onChange={(event) => {setWhatsAppText(event.target.value)}} 
                      required/>
                </div> 
              </form>

            </div> 
          </div> 
        </div> 
      </section>
    </>

    )
}

export default Contact
