// Import Swiper React components

import { Pagination ,Navigation, EffectCube, Lazy} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import press from '../data/press.json'

import 'swiper/css';
import "swiper/css/lazy";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "swiper/css/effect-cube";



export function Press(props){

  return (
    <>
    <section id = "press" className = "press">
        <div className = "container">
          <div className = "section-title">
            <h2> הופעות בתקשורת </h2> 
            <p>מוזמנת לראיונות בערוצים השונים כדיאטנית וכפסיכולוגית המומחית לטיפול בצליאק ורגישות לגלוטן, בנושא דיאטות שונות ומגוונות לירידה במשקל ובנושאי תזונה, גוף ונפש.</p>
          </div>

            <Swiper 
                modules={[Pagination, Navigation, Lazy ,EffectCube]}
                effect={"cube"}
                grabCursor={true}
                cubeEffect={{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                centeredSlides = {true}
                autoHeight = {true}
                loop =  {true}
                preloadImages = {false}
                lazy = {{checkInView: true}}
                slidesPerView = {1}
                navigation = {true}
                pagination={{clickable: true,}}
                keyboard  = {{enabled: true, onlyInViewport: true,}}
                className="press-slider"
                >
                {press.pressData.map((item,index) => 
                    <SwiperSlide key={item.key}>
                        <div className = "press-item" data-aos = "fade-up">
                            <div className = "youtube-container">
                                <iframe 
                                    data-src = {item.dataSrc}
                                    className = "swiper-lazy youtube-video"
                                    title = "YouTube video player"
                                    frameBorder = "0"
                                    allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen> 
                                </iframe> 
                                <div className = "swiper-lazy-preloader"> </div> 
                            </div> 
                            <p> {item.description} </p> 
                        </div> 
                    </SwiperSlide>
                )}
            </Swiper>
        </div> 
    </section>

    </>
  );
};

export default Press