import {useEffect} from 'react';

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
        return [...document.querySelectorAll(el)]
        } else {
        return document.querySelector(el)
        }
    }

    // Easy event listener function
    const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all)
        if (selectEl) {
        if (all) {
            selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
            selectEl.addEventListener(type, listener)
        }
        }
    }

    // Easy on scroll event listener 
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    // Scrolls to an element with header offset
    const scrollto = (el) => {
        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos,
            behavior: 'smooth'
        })
    }
        


export function Header (props)
{

     useEffect(() => {

        // Navbar links active state on scroll
        let navbarlinks = select('#navbar .scrollto', true)
        const navbarlinksActive = () => {
            let position = window.scrollY + 200
            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) 
                    return
                let section = select(navbarlink.hash)
                if (!section) 
                    return
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    navbarlink.classList.add('active')
                } else {
                    navbarlink.classList.remove('active')
                }
            })
        }
        
        window.addEventListener('load', navbarlinksActive)

        onscroll(document, navbarlinksActive)
        
        // Mobile nav toggle
        on('click', '.mobile-nav-toggle', function(e) {
            select('body').classList.toggle('mobile-nav-active')
            this.classList.toggle('bi-list')
            this.classList.toggle('bi-x')
        })
        
        // crool with ofset on links with a class name .scrollto
        on('click', '.scrollto', function(e) {
            if (select(this.hash)) {
                e.preventDefault()
            
                let body = select('body')
                if (body.classList.contains('mobile-nav-active')) {
                    body.classList.remove('mobile-nav-active')
                    let navbarToggle = select('.mobile-nav-toggle')
                    navbarToggle.classList.toggle('bi-list')
                    navbarToggle.classList.toggle('bi-x')
                }
                scrollto(this.hash)
            }
        }, true)
        
        
        // Scroll with ofset on page load with hash links in the url
        window.addEventListener('load', () => {
            if (window.location.hash) {
                if (select(window.location.hash)) {
                    scrollto(window.location.hash)
                }
            }
        });

     }, []);
    

    return( 
    <>
        <i className = "bi bi-list mobile-nav-toggle d-xl-none"> </i>

        <header id = "header"> 
            <div className = "d-flex flex-column bg-gradiant-primary">
                <div className = "profile">
                    <img src = "./assets/img/profile-img.jpg" alt = "" className = "img-fluid rounded-circle"></img>
                    <h1 className = "text-light"> <a href = "index.html"> רוני הולנדר </a></h1>
                    <div className = "social-links mt-3 text-center">
                    <a href = "https://www.facebook.com/%D7%A8%D7%95%D7%A0%D7%99-%D7%94%D7%95%D7%9C%D7%A0%D7%93%D7%A8-%D7%93%D7%99%D7%90%D7%98%D7%A0%D7%99%D7%AA-%D7%A7%D7%9C%D7%99%D7%A0%D7%99%D7%AA-%D7%95%D7%A4%D7%A1%D7%99%D7%9B%D7%95%D7%9C%D7%95%D7%92%D7%99%D7%AA-911408512319229/"
                        className = "facebook" target = "_blank" rel="noreferrer"> <i className = "bx bxl-facebook"> </i></a>
                    <a href = "https://www.instagram.com/nutri.roni/" className = "instagram" target = "_blank" rel="noreferrer"> <i className = "bx bxl-instagram"> </i></a>
                    <a href = "https://wa.me/972522541131" className = "whatsapp" target = "_blank" rel="noreferrer"> <i className = "bx bxl-whatsapp"> </i></a>
                    </div> 
                </div>

                <nav id = "navbar" className = "nav-menu navbar">
                    <ul>
                    <li> <a href = "#home" className = "nav-link scrollto active"> <i className = "bx bx-home"> </i> <span>דף הבית</span> </a></li>
                    <li> <a href = "#about" className = "nav-link scrollto"> <i className = "bx bx-user"> </i> <span>אודות</span> </a></li>
                    <li> <a href = "#services" className = "nav-link scrollto"> <i className = "bx bx-book-content"> </i> <span>תחומי טיפול </span></a> </li> 
                    <li> <a href = "#press" className = "nav-link scrollto"> <i className = "bx bx-file-blank"> </i> <span>הופעות בתקשורת </span></a></li> 
                    <li> <a href = "#testimonials" className = "nav-link scrollto"> <i className = "bx bx-server"> </i> <span>סיפורי הצלחה </span></a> </li> 
                    <li> <a href = "#recipes" className = "nav-link scrollto"> <i className = "bx bx-cookie"> </i> <span>מתכונים </span></a> </li> 
                    <li> <a href = "#contact" className = "nav-link scrollto"> <i className = "bx bx-envelope"> </i> <span>צור קשר</span> </a></li>
                    </ul> 
                </nav>
            </div> 
        </header>
    </>
    )
}

export default Header
