

export function About (props)
{

  function AboutLi(props){
    return <li> <i className = "bi bi-chevron-left"></i> <strong>{props.title ? props.title:""}</strong> <span>{props.text}</span></li>
  }

    return( 
        <section id = "about" className = "about">
        <div className = "container">
          <div className = "section-title">
            <h2> אודות </h2> 
            <p> נעים להכיר, שמי רוני הולנדר דיאטנית קלינית ופסיכולוגית.אני מטפלת ומאמנת אנשים באופן פרטני וקבוצתי לתזונה מאוזנת, למשקל תקין, לניהול מיטבי של מחלות כמו צליאק, סוכרת, כבד שומני ואיזון שומנים בדם וכן מלווה אנשים לפני ואחרי ניתוח בריאטרי. </p> 
            <br></br> 
            <p>שני תחומים סקרנו אותי תמיד: הגוף והנפש, את שניהם למדתי ובשניהם התמחיתי.</p>
            <p>לימוד ועיסוק בשני התחומים : פסיכולוגיה ותזונה, מאפשר לי מתן טיפול תזונתי שלם והוליסטי.</p>
            <p>שליחותי היא להוביל אנשים לחיים בריאים, מאוזנים, שלמים ומספקים במשקל תקין, בבריאות טובה, בקלות ובשלום עם הגוף והנפש.</p>
          </div>

          <div className = "row">
            <div className = "col-lg-4" data-aos = "fade-right">
              <img src = "assets/img/profile-img.jpg" className = "img-fluid" alt = ""/>
            </div> 
            <div className = "col-lg-8 pt-4 pt-lg-0 content" data-aos = "fade-left">
              <h3> השכלה </h3> 
              <ul>
                <AboutLi title =  "תואר Bsc במדעי התזונה, " text = "אוניברסיטת אריאל"/>
                <AboutLi title =  "תואר MA בפסיכולוגיה, "   text = "אוניברסיטת תל אביב"/>
                <AboutLi title =  "קורסים מקצועיים:"        text = "טיפול תזונתי בסוכרת, בריאטריה, גסטרואנטרולוגיה, אנדומטריוזיס, תזונה קטוגנית ועוד."/>
                <AboutLi title =  "קורסים טיפוליים: "       text = "טיפול באכילה רגשית, טיפול בעזרת קלפים טיפוליים, NLP, CBT, אימון בגישת ה NLP"/>
              </ul> 
              
              <h3> נסיון מקצועי </h3> 
              <ul>
                <AboutLi text = "בעלת קליניקה פרטית בתל מונד"/>
                <AboutLi text = "דיאטנית קלינית במכבי שרותי בריאות בתל מונד ובכפר סבא הירוקה"/>
                <AboutLi text = "מנחת סדנאות לירידה במשקל, אורח חיים בריא ואיזון סוכרת"/>
                <AboutLi text = "מרצה בנושאים מגוונים "/>
                <AboutLi text = "מנחת סיורים בסופר במגוון מיקודים"/>
                <AboutLi text = "דיאטנית במכון הבריאטרי בנתניה"/>
                <AboutLi text = "מתנדבת ומרצה מטעם עמותת הצליאק"/>
              </ul> 
            </div> 
          </div>
        </div> 
      </section>
    )
}

export default About
