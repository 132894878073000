// Import Swiper React components

import { Pagination ,Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import testimonials from '../data/testimonials.json'

import 'swiper/css';
import 'swiper/css/pagination';
import 'boxicons/css/boxicons.min.css'


export function Testimonials(props){

  return (
    <>
    <section id = "testimonials" className = "testimonials section-bg">
      <div className = "container">
          <div className = "section-title" >
          <h2>סיפורי הצלחה</h2> 
          <div className = "underline"></div>
          </div>

          <Swiper 
              pagination={{clickable: true,}}
              modules={[Pagination, Autoplay ]}
              speed = {600}
              autoplay =  {{delay: 7000, disableOnInteraction: false}}                           
              spaceBetween={20}
              loop={true}
              slidesPerView = {'auto'}
              breakpoints = {{
                              320: {slidesPerView: 1},
                              800: {slidesPerView: 2},
                              1200: {slidesPerView: 3}
                            }}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
              className="testimonials-slider"
              >
              {testimonials.testimonialsData.map((testimonial,index) => 
                  <SwiperSlide key={index}>
                      <div className = "testimonial-item" data-aos = "fade-up">
                      <p> <i className = "bx bxs-quote-alt-right quote-icon-right"></i> {testimonial.description} <i className = "bx bxs-quote-alt-left quote-icon-left"> </i> </p> 
                      <h3> {testimonial.name} </h3> 
                      <h4>{testimonial.place} </h4> 
                      </div> 
                  </SwiperSlide>
              )}
          </Swiper>
      </div> 
    </section>

    </>
  );
};

export default Testimonials