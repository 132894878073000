import {forwardRef} from 'react';
import {Dialog, DialogContent, Slide ,IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,EffectFade} from 'swiper';
import recipes from '../data/recipes.json'

import "swiper/css";
import "swiper/css/effect-fade";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function RecipesDialog(props){

    const {recipesDialogOpen, closeRecipesDialog,recipeIndex} = props

    const closeDialog = ()=>{ 
        closeRecipesDialog();
    }

    return(

        <Dialog 
            className="recipe-dialog" 
            open={recipesDialogOpen} 
            onClose={closeDialog}
            style={{ zIndex: 100000}}
            PaperProps={{sx: {maxWidth: "96%",MinWidth: "96%",}}}
            TransitionComponent={Transition}
        >

            <IconButton
                aria-label="close"
                onClick={closeDialog}
                sx={{
                    position: 'absolute',
                    left: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                 }}
                 >
                <CloseIcon />
            </IconButton>

          {/* <DialogTitle >מתכונים</DialogTitle> */}

          <DialogContent>

          <Swiper 
                speed = {600}
                // modules={[Navigation ]}
                // navigation = {true}
                loop={true}
                slidesPerView = {1}
                initialSlide ={recipeIndex}
                >
                {recipes.recipesData.map((recipe,index) => 
                    <SwiperSlide key={index}>
                        <section id="recipes-details" className="recipes-details">
                            <div className="container">
                                <div className="row gy-4">
                                    <div className="col-lg-8">
                                        <Swiper 
                                            speed = {600}
                                            // pagination={true} 
                                            effect={"fade"}
                                            fadeEffect={{ crossFade: true }}
                                            modules={[Autoplay,EffectFade ]}
                                            autoplay =  {{delay: 5000, disableOnInteraction: false}}                           
                                            loop={true}
                                            slidesPerView = {1}
                                            className="recipes-details-slider"
                                            >

                                            {recipe.images.map((image,index) => 
                                                <SwiperSlide key={index}>
                                                    <div className="swiper-slide">
                                                        <img src= {"assets/img/recipes/" + image} alt=""/>
                                                    </div>
                                                </SwiperSlide>
                                            )}
                                        </Swiper>

                                    </div>

                                    <div className="col-lg-4" dir="rtl">
                                        <div className="recipes-info">
                                        <h2>{recipe.title}</h2>
                                        <ul>
                                            {recipe.ingredients.map((ingredient,index) => 
                                                <li key={index}>{ingredient}</li>
                                            )}
                                        </ul>
                                        </div>
                                        <div className="recipes-description">
                                        <h3>הכנה</h3>
                                            {recipe.instructions.map((instruction,index) => 
                                                <p key={index}>{instruction}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </SwiperSlide>
                )}
            </Swiper>
          </DialogContent>
          </Dialog>
    )
}

export default RecipesDialog