import {useRef, useEffect} from 'react';
import Typed from 'typed.js';

export function Home (props)
{

// Create reference to store the DOM element containing the animation
const el = useRef(null);
// Create reference to store the Typed instance itself
const typed = useRef(null);

useEffect(() => {
  const options = {
    strings: [
      "ירידה במשקל",
      "צליאק ורגישות לגלוטן",
      "תזונה מותאמת לסוכרת",
      "איזון שומנים בדם וכבד שומני",
      "ליווי סביב ניתוחים בריאטריים",
      "איזון לחץ דם",
    ],
    loop: true,
    typeSpeed: 50,
    backSpeed: 30,
    backDelay: 2000
};
  
  // elRef refers to the <span> rendered below
  typed.current = new Typed(el.current, options);
  
  return () => {
    // Make sure to destroy Typed instance during cleanup
    // to prevent memory leaks
    typed.current.destroy();
  }
}, [])

    return( 
        <section id = "home" className = "d-flex flex-column justify-content-center align-items-center">
        <div className = "home-container">
          <h1> רוני הולנדר </h1> 
          <p> דיאטנית קלינית ופסיכולוגית </p> 
          <div className="type-wrap">
            <p><span ref={el} className = "typed" /></p>
          </div>
        </div> 
      </section>
    )
}

export default Home
