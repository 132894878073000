import {useState, useEffect} from 'react';
import {Header, Home, About, Services, Press, Testimonials, Recipes, Contact} from './Components'
import AOS from 'aos'
import 'aos/dist/aos.css'


function App() {

  // const [headerDisplay,setHeaderDisplay] = useState('block')
  const [showBackToTop,setShowBackToTop] = useState(false)

  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false
  })


  useEffect(() => {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
          setShowBackToTop(true)
        } else {
          setShowBackToTop(false)
        }
    });
}, []);


   
  return ( 

  <div className = "App">

    <Header/>
    <Home/>
    
    <main id = "main">
      <About/>
      <Services/>
      <Press/>
      <Testimonials/>
      <Recipes/>
      <Contact/>
    </main>

    { showBackToTop && (
      <a href = "#home" 
        className = "back-to-top d-flex align-items-center justify-content-center"> 
        <i className = "bi bi-arrow-up-short"> </i>
      </a>
    )}

    </div>
  );
}

export default App;